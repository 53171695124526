import styled, { css } from 'styled-components';
import { above } from '../../../styles/tools/media';
import breakpoints from '../../../styles/settings/breakpoints';
import { Grid } from '../../../styles/generic/container';

type DividerProps = {
	backgroundColor: string
	desktopColor?: string
};

export const Divider__container = styled.section <DividerProps>`
	${props => props.backgroundColor && css`
		background-color: var(--${props.backgroundColor})
	`}
`;

export const Divider__inner = styled(Grid)``;


type DividerLineProps = {
	desktopColor?: string
};

export const Divider__line = styled.hr<DividerLineProps>`
	height: 2px;
	background-color: var(--primaryBackground);
	border: none;
	grid-column: 1 / -1;

	${props => props.desktopColor && css`
		${above(
	breakpoints[960],
	() => css`
		background-color: ${props.desktopColor}
	`
)}
	`}
`;
